<mat-table #table [dataSource]="dataSource">
  <ng-container matColumnDef="dateHour">
    <mat-header-cell *matHeaderCellDef> Data/hora do pedido </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.dateHour }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="productName">
    <mat-header-cell *matHeaderCellDef> Nome do Produto </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.productName }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="totalValue">
    <mat-header-cell *matHeaderCellDef> Valor Total </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.totalValue }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <small [style]="getStatusColor(element.status)">•</small>
      <span title="{{element.status}}" data-toggle="tooltip">
        {{ getStatusLabel(element.status) }}
      </span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="marketplace">
    <mat-header-cell *matHeaderCellDef> Marketplace </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container>
        <img src="../../../assets/images/icons/logo-mercado-livre.png" alt="Marketplace Logo" />
      </ng-container>
      <ng-template #textCell>
      </ng-template>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>