import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UseGuideService {

    private stepUseGuide$ = new BehaviorSubject<number>(0);

    updateValueStepUseGuide(nextStep: number): void {
        this.stepUseGuide$.next(nextStep);
    }

    public getCurrentStep() {
        return this.stepUseGuide$.asObservable();
    }

    stopUseGuide(): void {
        this.updateValueStepUseGuide(0);
        this.stepUseGuide$.unsubscribe();
    }
}