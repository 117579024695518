import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AuthEnum } from '../../shared/models/enums/auth.enum';
import { ModalInitEnum } from '../../shared/models/enums/modal-init.enum';
import { ModalAccountConnectedComponent } from '../modal-account-connected/modal-account-connected.component';
import { AuthService } from '../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderComponent } from '../loader/loader.component';
import { Router } from '@angular/router';
import { AuthTokenService } from '../../../services/auth-token.service';
@Component({
  selector: 'app-modal-welcome',
  standalone: true,
  imports: [MatIconModule, ModalAccountConnectedComponent, LoaderComponent],
  templateUrl: './modal-welcome.component.html',
  styleUrl: './modal-welcome.component.scss'
})
export class ModalWelcomeComponent {
  constructor(
    private dialogRef: MatDialog,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private authTokenService: AuthTokenService
  ) { }

  mercadoLibreTxt = AuthEnum.authorizeConnectionMLText;
  welcomeText = ModalInitEnum.welcomeText;
  welcomeSubText = ModalInitEnum.welcomeSubText;
  infoConnectML = ModalInitEnum.infoConnectML;
  connectMLText = ModalInitEnum.connectMLText;
  infoGeneralText = ModalInitEnum.infoGeneralText;
  skipTips = ModalInitEnum.skipTips;
  useGuideButtonLabel = ModalInitEnum.useGuideButtonLabel;

  isLoading: boolean = false;

  closeModal(): void {
    this.dialogRef.closeAll();
  }

  redirectToExternalUrl(url: string) {
    window.location.assign(url);
  }

  initConnectionWithML() {
    this.isLoading = true;

    this.authService.initAuthorizationMercadoLivre().subscribe(res => {
      const { error } = res;

      if (error) {
        const { message } = res;

        if(message === 'Invalid Token') {
          // this.authTokenService.updateToken(this.initConnectionWithML);

          return;
        }

        this.toastr.error(message || 'Erro ao carregar');
        this.isLoading = false;

        return;
      }

      const { detail } = res;

      if (detail) {
        this.toastr.error(detail);
        this.isLoading = false;

        return;
      }

      const { body } = res;
      this.redirectToExternalUrl(body.url);
    });
  }
}