import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Input, ViewEncapsulation, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UseGuideService } from '../../../services/use-guide.service';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-dashlet',
  standalone: true,
  imports: [NgClass, MatIconModule, AsyncPipe, RouterLink, RouterLinkActive],
  templateUrl: './dashlet.component.html',
  styleUrl: './dashlet.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DashletComponent {
  @Input() title!: string;
  @Input() fixedColumns!: boolean;
  @Input() isTable!: boolean;
  @Input() infoOrderStatus = false;

  useGuideService = inject(UseGuideService);
  currentStep$ = this.useGuideService.getCurrentStep();

  unSubcribeStopUseGuide(): void {
    this.useGuideService.stopUseGuide();
  }

  goToNextStep(nextStep: number): void {
    this.useGuideService.updateValueStepUseGuide(nextStep);
  }
}
