import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AccountService } from '../../../services/accounts.service';
import { AuthService } from '../../../services/auth.service';
import { DashboardService } from '../../../services/dashboard.service';
import { UseGuideService } from '../../../services/use-guide.service';
import { responseErrorInfo } from '../../components/auth/auth.component';
import { CardComponent } from '../../components/card/card.component';
import { ChartsComponent } from '../../components/charts/charts.component';
import { DashletMiniComponent } from '../../components/dashlet-mini/dashlet-mini.component';
import { DashletComponent } from '../../components/dashlet/dashlet.component';
import { FormComponent } from '../../components/forms/form/form.component';
import { HeaderSubComponent } from '../../components/header/header-sub/header-sub.component';
import { HeaderComponent } from '../../components/header/header.component';
import { LoaderPageComponent } from '../../components/loader-page/loader-page.component';
import { MapComponent } from '../../components/map/map.component';
import { MenuComponent } from '../../components/menu/menu.component';
import { ModalAccountConnectedComponent } from '../../components/modal-account-connected/modal-account-connected.component';
import { ModalWelcomeComponent } from '../../components/modal-welcome/modal-welcome.component';
import { TableComponent } from '../../components/table/table.component';
import {
  IChartMapStateInfo,
  ICurrentOrderStatusTodayInfo,
  IIncomeTodayInfo,
  ITableRow,
} from './dashboard.type';
import { MapChartInputData } from '../../components/map/map.type';
import { DateTime } from 'luxon';
import { StoreService } from '../../../services/store.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    MatSlideToggleModule,
    FormComponent,
    CardComponent,
    MenuComponent,
    HeaderComponent,
    HeaderSubComponent,
    DashletComponent,
    DashletMiniComponent,
    ChartsComponent,
    TableComponent,
    NgClass,
    NgFor,
    NgIf,
    ModalWelcomeComponent,
    ModalAccountConnectedComponent,
    MatIconModule,
    AsyncPipe,
    LoaderPageComponent,
    MapComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, OnDestroy {
  incomeTodayInfo!: IIncomeTodayInfo;

  updates = [
    {
      description: 'Entregue',
      status: 'delivered',
      value: '',
      path: '/pedidos?status=concluido',
    },
    {
      description: 'Cancelados',
      status: 'cancelled',
      value: '',
      path: '/pedidos?status=cancelado',
    },
    {
      description: 'Reclamações',
      status: 'processing',
      value: '',
      path: null,
    },
    {
      description: 'Perguntas Pendentes',
      status: 'delivered',
      value: '',
      path: null,
    },
    {
      description: 'Mensagens Não lidas',
      status: 'cancelled',
      value: '',
      path: null,
    },
    {
      description: 'Reclamações Pendentes',
      status: 'processing',
      value: '',
      path: null,
    },
  ];

  dailyRequests = [
    {
      description: 'Total de pedidos',
      value: '',
    },
    {
      description: 'Total de produtos',
      value: '',
    },
  ];

  columns = [
    {
      columnType: 'dateHour',
      columnName: 'Data/hora',
    },
    {
      columnType: 'productName',
      columnName: 'Produto',
    },
    {
      columnType: 'totalValue',
      columnName: 'Valor',
    },
    {
      columnType: 'status',
      columnName: 'Status',
    },
    {
      columnType: 'marketplace',
      columnName: 'Marketplace',
    },
  ] as any;

  rows: ITableRow[] = [];
  mostSellsStates: Array<any> = [];
  sellsPerStateChartData: MapChartInputData = [];
  invoicingChartData: any[] = [];

  //FIXME: This is a workaround to avoid the first request when the component is initialized [me desculpe]
  isFirstRequest = true;

  currentOrderStatusTodayInfo?: ICurrentOrderStatusTodayInfo;

  /**
   * Selected stores.
   */
  private selectedStoresSubscription: Subscription | undefined;

  /**
   * Stores ids.
   */
  public storesIds: number[] = [];

  constructor(
    private dialogRef: MatDialog,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private dashboardService: DashboardService,
    private accountService: AccountService,
    private storeService: StoreService
  ) {
    // this.updateLoading(true);
  }

  ngOnInit(): void {
    this.updateLoading(true);
    this.verifyIfConnectedWithML();
    this.approveConnectionWithML();

    this.selectedStoresSubscription = this.storeService.selectedStores$.subscribe((selectedStores) => {
      this.storesIds = selectedStores.map(
        (selectedStore) => selectedStore.id
      );

      if(this.isFirstRequest && this.isAccountMLConnected) {
        this.isFirstRequest = false;
        return;
      }

      this.triggerToFetchData(true);
    })
  }

  ngOnDestroy(): void {
    if (this.selectedStoresSubscription) {
      this.selectedStoresSubscription.unsubscribe();
    }
  }

  isChartLoading: boolean = false;
  private isLoading = new BehaviorSubject<boolean>(true);
  isLoading$ = this.isLoading.asObservable();
  isAccountMLConnected: boolean = sessionStorage.getItem('loginML') === 'yes';

  isAccountMLConnected$ = new BehaviorSubject<boolean>(
    this.isAccountMLConnected
  );

  updateValueIsConnectedML(isConnected: boolean): void {
    this.isAccountMLConnected$.next(isConnected);

    if (isConnected) {
      sessionStorage.setItem('loginML', 'yes');
    } else {
      sessionStorage.setItem('loginML', 'no');
      this.openModalWelcome();
    }
  }

  updateLoading(isLoading: boolean): void {
    this.isLoading.next(isLoading);
  }

  formatDateTable(date: string) {
    const day = date.split('T')[0].split('-');
    const time = date.split('T')[1].slice(0, 8);

    return `${day[2]}/${day[1]}/${day[0]} - ${time}`;
  }

  formatCurrency(value: string): any {
    const options = {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    };
    return new Intl.NumberFormat('pt-BR', options).format(Number(value));
  }

  getIncomesToday(): void {
    this.updateLoading(true);
    const today = DateTime.now();
    const dateFrom = today.startOf('day').toUTC().toISO();
    const dateTo = today.endOf('day').toUTC().toISO();
    this.dashboardService
      .dailyInvoicing(dateFrom, dateTo, this.storesIds)
      .subscribe((res: any) => {
        const { error_code } = res;

        if (error_code) {
          const { message } = res;
          this.toastr.error(message);
        } else {
          const { body } = res;
          const { income } = body;

          this.incomeTodayInfo = income;
        }
      });
  }

  triggerToFetchData(isConnected: boolean) {
    if (isConnected) {
      this.getIncomesToday();
      this.getChartMapInfo();
      this.getReportsOverviewToday();
      this.getOrdersSummary();
      this.getCurrentOrdersStatusToday();
      this.getOrderItemsTable();
    } else {
      // this.updateLoading(false);
      this.dialogRef.closeAll();
      this.openModalWelcome();
    }
  }

  verifyIfConnectedWithML() {
    this.updateLoading(true);
    this.accountService.getAccounts().subscribe((res: any) => {
      const { error_code } = res;

      if (error_code) {
        const { message } = res;
        sessionStorage.setItem('loginML', 'no');
        this.toastr.error(message);
        this.updateValueIsConnectedML(false);
        this.triggerToFetchData(false);
        // this.updateLoading(false);
      } else {
        const { body } = res;
        const { stores } = body;

        if (stores.length !== 0) {
          sessionStorage.setItem('loginML', 'yes');
          this.updateValueIsConnectedML(true);
          this.triggerToFetchData(true);
        } else {
          sessionStorage.setItem('loginML', 'no');
          this.updateValueIsConnectedML(false);
          this.triggerToFetchData(false);
          // this.updateLoading(false)
        }
      }
    });
  }

  getReportsOverviewToday(): void {
    this.updateLoading(true);
    const today = DateTime.now();
    const dateFrom = today.startOf('day').toUTC().toISO();
    const dateTo = today.endOf('day').toUTC().toISO();
    this.dashboardService
      .getReportsOverview(dateFrom, dateTo, this.storesIds)
      .subscribe((res: any) => {
        const { error_code } = res;

        if (error_code) {
          const { message } = res;
          this.toastr.error(message);
        } else {
          const { body } = res;
          const { claims, messages, questions, orders } = body;
          const { totalClaims, totalPendingClaims } = claims;
          const { totalPendingMessages } = messages;
          const { totalPendingQuestions } = questions;
          const { totalCanceled, totalDelivered } = orders;

          this.updates = this.updates.map((update) => {
            switch (update.description) {
              case 'Entregue':
                const isEmpty = totalDelivered === 0;
                return {
                  ...update,
                  value: `${totalDelivered}`,
                  path: isEmpty ? '' : '/pedidos?status=DELIVERED',
                };
              case 'Reclamações':
                return {
                  ...update,
                  value: `${totalClaims}`,
                };
              case 'Reclamações Pendentes':
                return {
                  ...update,
                  value: `${totalPendingClaims}`,
                };
              case 'Cancelados':
                const isEmptyCancel = totalCanceled === 0;
                return {
                  ...update,
                  value: `${totalCanceled}`,
                  path: isEmptyCancel ? '' : '/pedidos?status=CANCELLED',
                };
              case 'Perguntas Pendentes':
                return {
                  ...update,
                  value: `${totalPendingQuestions}`,
                };
              case 'Mensagens Não lidas':
                return {
                  ...update,
                  value: `${totalPendingMessages}`,
                };
              default:
                return update;
            }
          });
        }
      });
  }

  redirectToExternalUrl(url: string) {
    window.location.assign(url);
  }

  initConnectionWithML(): void {
    this.updateLoading(true);

    this.authService.initAuthorizationMercadoLivre().subscribe((res: any) => {
      const { error } = res;

      if (error) {
        const { message } = res;

        this.toastr.error(message || 'Erro ao carregar');

        return;
      }

      const { detail } = res;

      if (detail) {
        this.toastr.error(detail);

        return;
      }

      const { body } = res;
      this.redirectToExternalUrl(body.url);
    });
  }

  approveConnectionWithML(): void {
    const params = this.route.snapshot.queryParams;
    const code = params['code'];
    const state = params['state'];

    if (code && state) {
      this.authService
        .aproveAuthorizationMercadoLivre({ code, state })
        .subscribe((res) => {
          const { error } = res;
          if (error) {
            const { message } = res;
            if (message === 'Store already exists') {
              this.authService.saveStatusAuthorizationMercadoLivre();
              this.updateValueIsConnectedML(true);
              this.toastr.warning(responseErrorInfo[message]);
              const IsRedirectToAccount =
                localStorage.getItem('UpdateAccountName');

              if (IsRedirectToAccount) {
                this.router.navigate(['contas']);
              }

              return;
            }

            this.toastr.error(
              responseErrorInfo[message] || 'Erro ao vincular conta'
            );
            return;
          }

          const { body } = res;
          const { store } = body;
          localStorage.setItem('AccountApproveML', JSON.stringify(store));
          const IsRedirectToAccount = localStorage.getItem('UpdateAccountName');

          if (IsRedirectToAccount) {
            this.router.navigate(['contas']);
          } else {
            this.toastr.success('Conta vinculada');
          }

          this.openModalAccountMlConnected();
          this.authService.saveStatusAuthorizationMercadoLivre();
          this.updateValueIsConnectedML(true);
        });
    }
  }

  getOrdersSummary(): void {
    this.updateLoading(true);
    this.dashboardService
      .getOrdersSummary(this.storesIds)
      .subscribe((res: any) => {
        const { error_code } = res;

        if (error_code) {
          const { message } = res;
          this.toastr.error(message);
        } else {
          const { body } = res;
          const { summary } = body;
          const { totalOrders, totalProducts } = summary;

          this.dailyRequests = this.dailyRequests.map((info) => {
            if (info.description === 'Total de pedidos') {
              return {
                ...info,
                value: `${totalOrders}`,
              };
            }

            if (info.description === 'Total de produtos') {
              return {
                ...info,
                value: `${totalProducts}`,
              };
            }

            return info;
          });
        }
      });
  }

  getCurrentOrdersStatusToday(): void {
    this.updateLoading(true);
    const today = DateTime.now();
    const dateFrom = today.startOf('day').toUTC().toISO();
    const dateTo = today.endOf('day').toUTC().toISO();
    this.dashboardService
      .getCurrentStatus(dateFrom, dateTo, this.storesIds)
      .subscribe((res: any) => {
        const { error_code } = res;

        if (error_code) {
          const { message } = res;
          this.toastr.error(message);
        } else {
          const { body } = res;
          const { summary } = body;

          this.currentOrderStatusTodayInfo = summary;
        }
      });
  }

  getOrderItemsTable(): void {
    this.updateLoading(true);
    const today = DateTime.now();
    const dateFrom = today.startOf('day').toUTC().toISO();
    const dateTo = today.endOf('day').toUTC().toISO();
    this.dashboardService.listOrderItemsToday(dateFrom, dateTo, this.storesIds).subscribe((res: any) => {
      const { error_code } = res;
      if (error_code) {
        const { message } = res;
        this.toastr.error(message);
      } else {
        const { body } = res;
        const { orders } = body;

        this.rows = orders.map((order: any) => {
          const formatDate = this.formatDateTable(
            order?.orderItems[0].order.externalCreatedAt
          );

          return {
            dateHour: formatDate,
            productName: order?.orderItems[0].title,
            totalValue: this.formatCurrency(order?.totalAmount),
            status: order?.orderItems[0].order.status,
            marketplace: 'logo-mercado-livre.png',
          };
        });
      }
    });
  }

  openModalWelcome(): void {
    this.dialogRef.open(ModalWelcomeComponent);
  }

  openModalAccountMlConnected(): void {
    this.dialogRef.open(ModalAccountConnectedComponent);
  }

  useGuideService = inject(UseGuideService);
  currentStep$ = this.useGuideService.getCurrentStep();

  unSubcribeStopUseGuide(): void {
    this.useGuideService.stopUseGuide();
  }

  goToNextStep(nextStep: number): void {
    this.useGuideService.updateValueStepUseGuide(nextStep);
  }

  getChartMapInfo(): void {
    this.updateLoading(true);
    const today = DateTime.now();
    const dateFrom = today.startOf('day').toUTC().toISO();
    const dateTo = today.endOf('day').toUTC().toISO();
    this.dashboardService
      .chartMapOrdersStates(dateFrom, dateTo, this.storesIds)
      .subscribe((res: any) => {
        const { error_code, error } = res;

        if (error_code || error) {
          const { message } = res;
          this.toastr.error(message);
        } else {
          const { body } = res;
          const { summary } = body;

          this.sellsPerStateChartData = summary.map(
            (state: IChartMapStateInfo) => ({
              id: `BR-${state.state}`,
              value: state.totalOrders,
            })
          );
          
          const filteredMostSellsStates = summary
            .filter((state: IChartMapStateInfo) => state.totalOrders > 0)
            .sort(
              (a: IChartMapStateInfo, b: IChartMapStateInfo) =>
                b.totalOrders - a.totalOrders
            )
            .slice(0, 3);
          this.mostSellsStates = filteredMostSellsStates;
        }

        setTimeout(() => {
          this.updateLoading(false);
        }, 500);
      });
  }
}
