import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  Component,
  Input,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

interface TableColumn {
  columnType: string;
  columnName: string;
}

interface Product {
  dateHour: string;
  productName: string;
  totalValue: string;
  status: string;
  marketplace: string;
  [key: string]: any;
}

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [MatTableModule, NgFor, NgIf, NgClass],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent {
  displayedColumns: string[] = [];
  dataSource!: MatTableDataSource<Product>;

  private _columns: TableColumn[] = [];
  private _rows: Product[] = [];
  private statusClassCache: { [key: string]: string } = {};

  @Input()
  set columns(values: TableColumn[]) {
    this._columns = values;
    this.displayedColumns = this._columns.map((column) => column.columnType);
  }

  get columns(): TableColumn[] {
    return this._columns;
  }

  @Input()
  set rows(values: Product[]) {
    this._rows = values;
    this.dataSource = new MatTableDataSource<Product>(this._rows);
  }

  get rows(): Product[] {
    return this._rows;
  }

  get getColumnTypes(): string[] {
    return this.displayedColumns;
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Product>(this.rows);
    this.preProcessStatusClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rows']) {
      this.dataSource = new MatTableDataSource<Product>(this.rows);
      this.preProcessStatusClasses();
    }
  }

  preProcessStatusClasses(): void {
    this.rows.forEach((row) => {
      if (row.status) {
        row['statusClass'] = this.getStatusClass(row.status);

        this.getStatusColor(row.status);
        this.getStatusLabel(row.status);
      }
    });
  }

  getStatusClass(status: string): string {
    if (this.statusClassCache[status]) {
      return this.statusClassCache[status];
    }

    let statusClass = '';
    switch (status) {
      case 'cancelled':
        statusClass = '--cancelled';
        break;
      case 'processing':
        statusClass = '--processing';
        break;
      default:
        statusClass = '';
    }

    this.statusClassCache[status] = statusClass;
    return statusClass;
  }

  getStatusColor(status: string) {
    switch (status) {
      case 'PENDING':
      case 'HANDLING':
      case 'READY_TO_SHIP':
        return 'color: #fec601'; // AMARELO
      case 'HANDLING_DELAYED':
      case 'SHIPMENT_DELAYED':
      case 'DELIVERY_DELAYED':
      case 'CLAIM':
        return 'color: #ea7317'; // LARANJA
      case 'SHIPPED':
        return 'color: #2364aa'; // AZUL
      case 'DELIVERED':
        return 'color: #4AD991'; // VERDE
      case 'CANCELLED':
      case 'CONFISCATED':
        return 'color: #EB5757'; // VERMELHO
      case 'UNMAPPED':
        return 'color: #c0c0c0'; // CINZA
      default:
        return 'color: inherit';
    }
  }

  getStatusLabel(status: string) {
    switch (status) {
      case 'PENDING':
        return 'Em revisão';
      case 'HANDLING':
        return 'A preparar';
      case 'READY_TO_SHIP':
        return 'Pronto para enviar';
      case 'HANDLING_DELAYED':
        return 'Preparação atrasada';
      case 'SHIPMENT_DELAYED':
        return 'Envio atrasado';
      case 'DELIVERY_DELAYED':
        return 'Entrega atrasada';
      case 'SHIPPED':
        return 'A caminho';
      case 'DELIVERED':
        return 'Concluído';
      case 'CANCELLED':
        return 'Cancelado';
      case 'UNMAPPED':
        return 'Devolução';
      case 'CONFISCATED':
        return 'Apreendido';
      case 'CLAIM':
        return 'Reclamação aberta';
      default:
        return 'Status desconhecido';
    }
  }

  getCellContent(element: Product, column: TableColumn): string | undefined {
    if (column.columnType === 'marketplace') {
      return `<img src="../assets/images/icons/${element.marketplace}" alt="Marketplace Logo" />`;
    } else {
      if (element.hasOwnProperty(column.columnType)) {
        return element[column.columnType] as string;
      } else {
        return undefined;
      }
    }
  }
}
