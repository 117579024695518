<div class="dashlet">
  <div class="dashlet__heading">
    <div>
      @if(title === 'Faturamento') {
      <img src="../../../assets/images/icons/faturamento.svg" alt="faturamento">
      }
      @if(title === 'Atualizações do dia') {
      <img src="../../../assets/images/icons/atualizacoes-do-dia.svg" alt="Atualizações">
      }
      @if(title === 'Últimos pedidos') {
      <img src="../../../assets/images/icons/detalhes-do-pedido.svg" alt="faturamento">
      }
      @if(title === 'Pedidos por estado') {
      <img src="../../../assets/images/icons/pedidos-por-estado.svg" alt="faturamento">
      }
      <h5>{{ title }}</h5>
    </div>

    @if(title === 'Faturamento') {
    <div>
      <a [routerLink]="['/metricas']" [routerLinkActive]="'is-active'">
        <button>VER GRÁFICOS <img style="margin-left: 8px;" src="../../../assets/images/icons/see-graphics.svg" alt="graphics"> </button>
      </a>
      <!-- <button>
        <img src="../../../assets/images/icons/icon-share.svg" alt="share button">
      </button> -->
    </div>
    }

    @if(title === 'Últimos pedidos') {
      <div>
        <a [routerLink]="['/pedidos']" [routerLinkActive]="'is-active'">
          <button>VER TODOS <img style="margin-left: 8px;" src="../../../assets/images/icons/detalhes-do-pedido-button.svg" alt="graphics"> </button>
        </a>
      </div>
      }

    @if(title === 'Faturamento' && ((currentStep$ | async) === 1)) {
    <div class="use-guide-tooltip-container">
      <div class="use-guide-tooltip">
        Valores referente as suas vendas no dia atual, com o percentua comparado ao mesmo dia do mês anterior

        <div>
          <p aria-hidden="true" (click)="unSubcribeStopUseGuide()">Ignorar dicas</p>

          <img aria-hidden="true" (click)="goToNextStep(3)" src="../../../assets/images/icons/arrow-right.svg"
            alt="next tip">
        </div>
      </div>
    </div>
    }
    @if(title === 'Atualizações do dia' && ((currentStep$ | async) === 3)) {
    <div class="use-guide-tooltip-container">
      <div class="use-guide-tooltip">
        Pedidos lançados nas últimas 24h, com a relação po status.

        <div>
          <p aria-hidden="true" (click)="unSubcribeStopUseGuide()">Ignorar dicas</p>

          <div>
            <img aria-hidden="true" (click)="goToNextStep(1)" src="../../../assets/images/icons/arrow-left.svg"
              alt="previous tip">
            <img aria-hidden="true" (click)="goToNextStep(4)" src="../../../assets/images/icons/arrow-right.svg"
              alt="next tip">
          </div>
        </div>
      </div>
    </div>
    }
    @if(title === 'Últimos pedidos' && ((currentStep$ | async) === 5)) {
    <div class="use-guide-tooltip-container">
      <div class="use-guide-tooltip">
        Os útimos pedidos de forma detalhada, para ver mais ou aplicar filtros clique em ver todos.

        <div>
          <p aria-hidden="true" (click)="unSubcribeStopUseGuide()">Ignorar dicas</p>

          <div>
            <img aria-hidden="true" (click)="goToNextStep(4)" src="../../../assets/images/icons/arrow-left.svg"
              alt="previous tip">
            <img aria-hidden="true" (click)="goToNextStep(6)" src="../../../assets/images/icons/arrow-right.svg"
              alt="next tip">
          </div>
        </div>
      </div>
    </div>
    }
  </div>
  <div class="dashlet__body" [ngClass]="{ '--fixed-columns': fixedColumns, '--grid-table': isTable }">
    <ng-content select="[content]"></ng-content>

  </div>

</div>