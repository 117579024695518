<div class="dashboard-modal-welcome">
    <div class="dashboard-modal-welcome__header">
        <div>
            <img src="../../../assets/images/icons/new-logo-capivara.svg">
            <h3>Conta conectada<br/>
                com sucesso!</h3>
        </div>
        <div aria-hidden="true" class="dashboard-modal-welcome__header-close" (click)="closeModal()">
            <img src="../../../assets/images/icons/close-modal.svg">
        </div>
    </div>
    <h2>Bem vindo a tela de Início!</h2>
    <div class="dashboard-modal-welcome__content">
        <p>Aqui você terá um panorama geral do seu faturamento e pedidos no dia atual em comparação ao mês anterior</p>

      <div class="dashboard-modal-buttons">
        <p aria-hidden="true" (click)="closeModal()">Pular dicas</p>
  
        <button (click)="startUseGuide()">
          VER DICAS DE USO
        </button>
      </div>
    </div>
</div>