<div class="dashboard-modal-welcome">
  <div class="dashboard-modal-welcome__header">
    <div>
      <img src="../../../assets/images/icons/new-logo-capivara.svg">
      <h3>{{welcomeText}}</h3>
    </div>
    <div aria-hidden="true" class="dashboard-modal-welcome__header-close" (click)="closeModal()">
      <img src="../../../assets/images/icons/close-modal.svg">
    </div>
  </div>
  <h2>Estamos quase lá!</h2>
  <div class="dashboard-modal-welcome__content">
    <p>Para visualizar seus dados na tela de início é necessário vincular ao menos uma conta</p>

    <button class="dashboard-modal-welcome__content-external-link-button" aria-hidden="true" (click)="initConnectionWithML()">
      <i class="icon --mercado-livre"></i>
      <span>
        <strong>Conectar Mercado Livre</strong>
        <mat-icon aria-hidden="false" fontIcon="open_in_new"></mat-icon>
      </span>
    </button>
    <!-- <p>{{infoGeneralText}}</p>

    <div class="dashboard-modal-buttons">
      <p aria-hidden="true" (click)="closeModal()">{{skipTips}}</p>

      <button (click)="startUseGuide()">
        {{useGuideButtonLabel}}
      </button>
    </div> -->
  </div>
</div>