import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-dashlet-mini',
  standalone: true,
  imports: [NgIf, NgClass, MatIconModule, RouterLink, RouterLinkActive],
  templateUrl: './dashlet-mini.component.html',
  styleUrl: './dashlet-mini.component.scss',
})
export class DashletMiniComponent implements OnInit {
  @Input() description? = '';
  @Input() hasBackground = false;
  @Input() percentage? = '';
  @Input() status? = '';
  @Input() value!: any;
  @Input() centerAllItems = false;
  @Input() redirect?: boolean = false;
  @Input() pathRedirect?: string;

  public percentageToShow?: {
    isPositive: boolean;
    value: number;
    formattedValue: string;
  };

  ngOnInit(): void {
    if (this.percentage) {
      this.percentageToShow = this.formatPercentDiff(this.percentage);
    }
  }

  formatPercentDiff(value: string): any {
    return {
      isPositive: !value.includes('-'),
      value: Number(value),
      formattedValue: `${Math.trunc(Number(value))}%`,
    };
  }
}
