<div class="content">
  @if(!(isLoading$ | async)) { @if(!(isAccountMLConnected$ | async)) {
  <div class="container-connect-ml">
    <div class="input-error">
      <mat-icon aria-hidden="false" fontIcon="error"></mat-icon>
      <span
        >Para visualizar seus dados é necessário vincular ao menos uma
        conta</span
      >
    </div>
    <button
      class="dashboard-modal-welcome__content-external-link-button"
      aria-hidden="true"
      (click)="initConnectionWithML()"
    >
      <i class="icon --mercado-livre"></i>
      <span>
        <strong>Conectar Mercado Livre</strong>
        <mat-icon aria-hidden="false" fontIcon="open_in_new"></mat-icon>
      </span>
    </button>
  </div>
  }
  <div class="content__dashlets">
    <div class="content__dashlets__item">
      <!-- Dashlet - Faturamento -->
      <app-dashlet [fixedColumns]="true" [title]="'Faturamento'">
        <app-dashlet-mini
          [value]="formatCurrency(incomeTodayInfo.income)"
          [description]="'Faturamento bruto'"
          [percentage]="incomeTodayInfo.incomePercentDiff"
          content
        ></app-dashlet-mini>
        <app-dashlet-mini
          [value]="formatCurrency(incomeTodayInfo.netIncome)"
          [description]="'Faturamento líquido'"
          [percentage]="incomeTodayInfo.netIncomePercentDiff"
          content
        ></app-dashlet-mini>
        <app-dashlet-mini
          [value]="formatCurrency(incomeTodayInfo.averageTicket)"
          [description]="'Ticket médio'"
          content
        ></app-dashlet-mini>
        @if(!isChartLoading) {
          <div class="full" content>
            <app-charts [description]="'Comparativo mensal'" [chartData]="invoicingChartData" content></app-charts>
          </div>
        }
      </app-dashlet>

      <!-- Dashlet - Gráficos -->
      <!-- <app-dashlet [title]="'Pedidos por estados'">
        </app-dashlet> -->
      <div class="states-per-state-container">
        <div class="title">
          <img
            src="../../../assets/images/icons/pedidos-por-estado.svg"
            alt="pedidos por estado"
          />
          <h5>Pedidos do dia por estado</h5>
        </div>
        <div class="map-container">
          <app-map [data]="sellsPerStateChartData"></app-map>
          <div class="list-orders-per-states">
            <p class="most-sell-states-title">Estados com mais vendas</p>
            
            @if (mostSellsStates.length > 0) { 
              @for (item of mostSellsStates; track $index) {
                <div class="map-info">
                  <p>{{ item.state }}</p>
                  <p>{{ item.totalOrders }}</p>
                </div>
              } 
            } @else {
              <div style="text-align: center;">Não há pedidos para nenhum estado</div>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="content__dashlets__item">
      <!-- Dashlet - Atualizações -->
      <app-dashlet [title]="'Atualizações do dia'">
        @for (item of updates; track $index) {
          <app-dashlet-mini
            [value]="item.value"
            [status]="item.status"
            [redirect]="!!item.path"
            [pathRedirect]="item.path ?? ''"
            [hasBackground]="true"
            content
            [description]="item.description"
          ></app-dashlet-mini>
        }
      </app-dashlet>

      <!-- Dashlet - Pedidos -->
      <!-- <app-dashlet [title]="'Pedidos do dia'" [infoOrderStatus]="true" >
          <app-dashlet-mini
            *ngFor="let item of dailyRequests"
            [description]="item.description"
            [value]="item.value"
            content
          ></app-dashlet-mini>
        </app-dashlet> -->
      <div class="orders-today-dashboard">
        <div>
          <img
            src="../../../assets/images/icons/pedidos-do-dia.svg"
            alt="Pedidos do dia"
          />
          <h5>Pedidos do dia</h5>

          @if((currentStep$ | async) === 4) {
          <div class="use-guide-tooltip-container">
            <div class="use-guide-tooltip">
              Pedidos lançados nas últimas 24h, com a relação por status.

              <div>
                <p aria-hidden="true" (click)="unSubcribeStopUseGuide()">
                  Ignorar dicas
                </p>

                <div>
                  <img
                    aria-hidden="true"
                    (click)="goToNextStep(3)"
                    src="../../../assets/images/icons/arrow-left.svg"
                    alt="previous tip"
                  />
                  <img
                    aria-hidden="true"
                    (click)="goToNextStep(5)"
                    src="../../../assets/images/icons/arrow-right.svg"
                    alt="next tip"
                  />
                </div>
              </div>
            </div>
          </div>
          }
        </div>
        <div class="orders-today-dashboard-content">
          <app-dashlet-mini
            [centerAllItems]="true"
            *ngFor="let item of dailyRequests"
            [description]="item.description"
            [value]="item.value"
            content
          ></app-dashlet-mini>
        </div>
        <div class="orders-today-dashboard-info">
          <div class="orders-today-dashboard-info__header">
            <h4>Status dos pedidos</h4>
            <div></div>
          </div>
          <div>
            <div class="dashlet__status-order-content">
              <div class="dashlet__status-order-info">
                <span class="sinc-status --neutral">A preparar</span>
                <strong>{{
                  currentOrderStatusTodayInfo?.totalHandling ?? 0
                }}</strong>
              </div>
              <mat-icon
                [attr.aria-label]="'next'"
                fontIcon="navigate_next"
              ></mat-icon>
              <div>
                <span class="sinc-status --warn">Pronto pra enviar</span>
                <strong>{{
                  currentOrderStatusTodayInfo?.totalReadyToShip ?? 0
                }}</strong>

                @if(!!currentOrderStatusTodayInfo?.totalShipmentDelayed) {
                <div class="order-error-info-shipping">
                  <img
                    src="../../../assets/images/icons/info-order-error.svg"
                    alt="order error"
                  />
                  <p>
                    {{ currentOrderStatusTodayInfo?.totalShipmentDelayed ?? 0 }}
                    itens com atraso
                  </p>
                </div>
                }
              </div>
              <mat-icon
                [attr.aria-label]="'next'"
                fontIcon="navigate_next"
              ></mat-icon>
              <div>
                <span class="sinc-status --green">Em trânsito vendidos</span>

                <strong>{{
                  currentOrderStatusTodayInfo?.totalShipped ?? 0
                }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dashlet">
        <div class="dashlet__heading">
          <div>
            <img
              src="../../../assets/images/icons/detalhes-do-pedido.svg"
              alt="faturamento"
            />
            <h5>Detalhes dos pedidos</h5>
          </div>

          @if((currentStep$ | async) === 5) {
          <div class="use-guide-tooltip-container">
            <div class="use-guide-tooltip">
              Os útimos pedidos de forma detalhada, para ver mais ou aplicar
              filtros clique em ver todos.

              <div>
                <p aria-hidden="true" (click)="unSubcribeStopUseGuide()">
                  Ignorar dicas
                </p>

                <div>
                  <img
                    aria-hidden="true"
                    (click)="goToNextStep(4)"
                    src="../../../assets/images/icons/arrow-left.svg"
                    alt="previous tip"
                  />
                  <img
                    aria-hidden="true"
                    (click)="goToNextStep(6)"
                    src="../../../assets/images/icons/arrow-right.svg"
                    alt="next tip"
                  />
                </div>
              </div>
            </div>
          </div>
          }
        </div>
        <div class="dashlet__body --grid-table">
          @if(rows.length > 0) {
          <app-table [columns]="columns" [rows]="rows" content></app-table>
          } @else {
          <div class="orders-no-content">
            <img
              src="../../../assets/images/icons/icon-no-content.svg"
              alt="sem dados"
            />
            <div>
              <h4>Poxa, que pena :(</h4>
              <p>Não há dados para visualização.</p>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
  } @else {
  <div class="container-logo">
    <app-loader-page></app-loader-page>
  </div>
  } @if(isLoading$ | async) { }
</div>
