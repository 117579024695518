@if(redirect) {
<a [routerLink]="['/pedidos']" [queryParams]="{ status: status }" [routerLinkActive]="'is-active'">
  <div class="dashlet-mini" [ngClass]="{ '--background': hasBackground }">
    <div class="dashlet-mini__heading">
      <p *ngIf="!status">{{ description }}</p>
      <p *ngIf="status" class="--status" [ngClass]="
            status === 'delivered'
              ? '--delivered'
              : status === 'cancelled'
              ? '--cancelled'
              : '--claims'
          ">
        <span>
          {{ description }}
        </span>
      </p>
    </div>
    <div class="dashlet-mini__body">
      <p *ngIf="value" [ngClass]="{ '--status': status, '--center-items': centerAllItems }">
        <strong>{{ value }}</strong>
      </p>
      <p *ngIf="percentageToShow?.value && +percentageToShow!.value !== 0" class="percentage">
        @if(percentageToShow?.isPositive) {
          <mat-icon aria-hidden="false" fontIcon="arrow_upward" class="percentage--positive"></mat-icon>
          <span class="percentage--positive">
            {{ percentageToShow?.formattedValue }}
          </span>
        } @else {
          <mat-icon aria-hidden="false" fontIcon="arrow_downward" class="percentage--negative"></mat-icon>
          <span class="percentage--negative">
            {{ percentageToShow?.formattedValue }}
          </span>
        }
      </p>
    </div>
  </div>

</a>

} @else {
<div class="dashlet-mini" [ngClass]="{ '--background': hasBackground }">
  <div class="dashlet-mini__heading">
    <p *ngIf="!status">{{ description }}</p>
    <p *ngIf="status" class="--status" [ngClass]="
          status === 'delivered'
            ? '--delivered'
            : status === 'cancelled'
            ? '--cancelled'
            : '--claims'
        ">
      <span>
        {{ description }}
      </span>
    </p>
  </div>
  <div class="dashlet-mini__body">
    <p *ngIf="value" [ngClass]="{ '--status': status, '--center-items': centerAllItems }">
      <strong>{{ value }}</strong>
    </p>
    <p *ngIf="percentageToShow?.value && +percentageToShow!.value !== 0" class="percentage">

      @if(percentageToShow?.isPositive) {
        <mat-icon aria-hidden="false" fontIcon="arrow_upward" class="percentage--positive"></mat-icon>
        <span class="percentage--positive">
          {{ percentageToShow?.formattedValue }}
        </span>
      } @else {
        <mat-icon aria-hidden="false" fontIcon="arrow_downward" class="percentage--negative"></mat-icon>
        <span class="percentage--negative">
          {{ percentageToShow?.formattedValue }}
        </span>
      }
    </p>
  </div>
</div>
}