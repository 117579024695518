import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UseGuideService } from '../../../services/use-guide.service';
@Component({
  selector: 'app-modal-account-connected',
  standalone: true,
  imports: [],
  templateUrl: './modal-account-connected.component.html',
  styleUrl: './modal-account-connected.component.scss'
})
export class ModalAccountConnectedComponent {
  constructor(private dialogRef: MatDialog) {}

  private useGuideService = inject(UseGuideService);

  closeModal(): void {
    this.dialogRef.closeAll();
  }

  startUseGuide(): void {
    this.closeModal();
    this.useGuideService.updateValueStepUseGuide(1);
  }
}
